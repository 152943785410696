<template>
    <header class="navbar">
      <div class="navbar-logo">
        <img src="@/assets/logo.png" alt="Logo" style="width: 24px;height: 24px;"/>
        <span class="navbar-text">PieceNote</span>
      </div>
      <!-- <div class="navbar-actions">
        <button @click="toggleTheme" class="theme-toggle">
          {{ theme === 'light' ? '🌞' : '🌜' }}
        </button>
        <a href="https://github.com/your-repo" target="_blank" class="github-link">
          GitHub
        </a>
      </div> -->
    </header>
    <div class="navbar-divider"></div> <!-- 新增的分割线 -->
  </template>
  
  <script>
  export default {
    data() {
      return {
        theme: 'light'
      };
    },
    methods: {
      toggleTheme() {
        this.theme = this.theme === 'light' ? 'dark' : 'light';
        document.body.className = this.theme;
      }
    }
  };
  </script>
  
  <style scoped>
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    padding: 0 0px;
    background-color: var(--background-color);
    color: var(--text-color);
    border-bottom: 1px solid var(--border-color);
  }
  
  .navbar-logo {
    margin-left: 32px;
    display: flex;
    align-items: center;
  }
  
  .navbar-logo img {
    height: 40px;
    margin-right: 10px;
  }
  
  .navbar-text {
    font-size: 1.2em;
    font-weight: bold;
  }
  
  .navbar-actions {
    display: flex;
    align-items: center;
    margin-right: 32px;
  }
  
  .theme-toggle {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    margin-right: 20px;
  }
  
  .github-link {
    text-decoration: none;
    color: var(--text-color);
    font-size: 1em;
  }
  
  body.light {
    --background-color: #ffffff;
    --text-color: #000000;
    --border-color: #dddddd;
  }
  
  body.dark {
    --background-color: #333333;
    --text-color: #ffffff;
    --border-color: #444444;
  }
  
/* 新增的分割线样式 */
.navbar-divider {
  height: 1px;
  background-color: rgba(60, 60, 60, 0.12);
  margin: 0 0px;
}
  </style>