<template>
  <div class="homepage">
    <h1>{{ msg }}</h1>
    <p>
      超快启动速度，超快响应时间，超低内存消耗，一款轻便的笔记软件，提供舒适的写作环境。<br>
    </p>
    <p>下载安装包
      <a :href="windows_download_app_url"> PieceNote_{{ windows_version }}.exe </a> (36MB).</p>
    <p class="version-info">版本 {{ windows_version }} Build {{ windows_build_number }} ，更新时间：{{ windows_build_time }} 访问量: {{ page_count }}</p>
    <img alt="PieceNote logo" src="@/assets/piecenote.png" style="width: 875px; height: 623px;">
    <div>
      <p class="p-contact">
      欢迎加入QQ群 629376653 邮箱 370283593@qq.com 微信 dansen-xia<br>
    </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HomePage',
  props: {
    msg: String
  },
  data() {
    return {
      windows_version: '0.0.0',
      windows_build_time: '1970/0/0 00:00',
      windows_build_number: '0',
      windows_download_app_url: 'https://v8cloud.oss-cn-hangzhou.aliyuncs.com/piece/PieceNote_0.0.0.exe',
      page_count: 0
    }
  },
  mounted() {
    this.setAppVersions();
  },
  methods: {
    getWindowsVersion(versions){
        var data
        versions.forEach(v => {
            if(v.Platform == "windows") {
              data = v
            }
        })
        return data
    },
    getTimeString(timestamp){
      var date = new Date(timestamp);
      return date.getFullYear()+"/"+(date.getMonth()+1)+"/"+date.getDate()+" "+date.getHours()+":"+date.getMinutes()
    },
    setAppVersions() {
      var that = this;
      axios.get('/dl/versions')
        .then(response => {
          var data = response.data;
          console.log(response.data);
          if(data.Code != 0) {
              return
          }
          var windows_version_config = that.getWindowsVersion(data.AppVersions)
          that.windows_version = windows_version_config.AppVersion
          that.windows_build_time = that.getTimeString(windows_version_config.UpdateTime*1000)
          that.windows_build_number = windows_version_config.BuildNumber
          that.windows_download_app_url = "https://v8cloud.oss-cn-hangzhou.aliyuncs.com/piece/PieceNote_"+windows_version_config.AppVersion+".exe"
          that.getPageCount()
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    },
    getPageCount() {
      var that = this;
      axios.get('/page/count')
        .then(response => {
          that.page_count = response.data.count;
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.version-info {
  font-size: 13px;
  color: #666666;
}
.p-contact {
  font-size: 14px;
  color: #666666;
}
</style>
