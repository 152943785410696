<template>
    <footer class="footer">
      <div class="container">
        <p>&copy; 武汉帛曲科技有限公司 版权所有 2022 - 2024 All right Reserved <a href="http://beian.miit.gov.cn/" target="_blank">鄂ICP备2022011351号</a>.</p>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterBar'
  }
  </script>
  
  <style scoped>
  .footer {
    background-color: #f8f9fa;
    padding: 20px 0;
    text-align: center;
    border-top: 1px solid #e7e7e7;
  }
  
  .footer .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer p {
    margin: 0;
    color: #6c757d;
    font-size: 13px;
  }
  </style>